import { InProgressProcessOptions } from "./in-progress-process-options.model";

export class InProgressProcess {
    options: InProgressProcessOptions = new InProgressProcessOptions();
    name: string;
    readonly id: number = Date.now();

    constructor(name: string, _options?: InProgressProcessOptions) {
        this.name = name;
        if (_options) {
            this.options = _options;
        }
    }
}
