import {
    computed,
    Injectable,
    Signal,
    signal,
    WritableSignal,
} from "@angular/core";
import { InProgressProcess } from "../data/in-progress-process.model";
import { InProgressProcessOptions } from "../data/in-progress-process-options.model";

@Injectable({
    providedIn: "root",
})
export class InProgressService {
    //Set of active processes
    private _activeProcesses: WritableSignal<Set<InProgressProcess>> = signal(
        new Set()
    );

    //Computed signal to check if there are any active processes
    public hasActiveProcess: Signal<boolean> = computed(
        () => this._activeProcesses().size > 0
    );

    /**
     * Start progress and create.
     * @param name Name of the process.
     * @returns InProgressProcess
     */
    start(
        name?: string,
        options?: InProgressProcessOptions
    ): InProgressProcess {
        //Creates a new InProgressProcess with the given name
        const newProcess = new InProgressProcess(name ?? "", options);

        //Updates the active processes set with the new process
        this._activeProcesses.update((process) => {
            process.add(newProcess);
            return new Set(process);
        });
        return newProcess;
    }

    /**
     * Remove the progress/process on active processes.
     * @param process InProgressProcess to remove.
     */
    end(process: InProgressProcess): void {
        //Updates the active processes set by removing the given process
        this._activeProcesses.update((activeProcess) => {
            activeProcess.delete(process);
            return new Set(activeProcess);
        });
    }
}
